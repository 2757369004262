import React, { ReactNode, useEffect } from "react";
import { toast } from "sonner";

import { Toaster } from "@/components/ui/sonner";
import { usePageData } from "@/hooks/usePageData";

const Global = ({ children }: { children: ReactNode }) => {
  const { flash } = usePageData();
  const path = window.location.pathname;

  useEffect(() => {
    if (path.startsWith("/qvac")) {
      window.tidioChatApi?.hide();
    }
  }, [path]);

  useEffect(() => {
    if (flash.success) {
      toast.success(flash.success);
    }
    if (flash.error) {
      toast.error(flash.error);
    }
  }, [flash.success, flash.error]);

  return (
    <>
      {children}
      <Toaster />
    </>
  );
};

export default Global;
