import { useEffect, useMemo, useState } from "react";
import Snowfall from "react-snowfall";

import { BorderBeam } from "@/components/magicui/border-beam";
import { QVirtualLogo } from "@/components/qvirtual-logo";
import { isChristmasPeriod } from "@/lib/utils";
import { useTheme } from "@/providers/ThemeProvider";

const bgImages = [
  "/assets/media/0c05101a-991f-42c4-948c-137ca8adbb76.webp",
  "/assets/media/54c2b767-5fea-4cf0-93c2-48e0d35ae399.webp",
  "/assets/media/a507a8d9-1701-4961-8082-0397435097e0.webp",
  "/assets/media/a2bc3071-d9d4-496d-838f-7bfae81e43b9.webp",
  "/assets/media/565c5001-2936-4354-b27e-7028167d4e9c.webp",
  "/assets/media/dde3f1e6-66b6-4ccc-b155-f91a6aacaff1.webp",
];

export default function GuestLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { theme } = useTheme();
  const themedLogoImage =
    theme === "dark"
      ? "/assets/images/logos/logo-lite-new.png"
      : "/assets/images/logos/logo-dark-new.png";

  const randomizedBgImages = useMemo(() => {
    return [...bgImages].sort(() => Math.random() - 0.5);
  }, []);

  const [currentBgImageIndex, setCurrentBgImageIndex] = useState(() =>
    Math.floor(Math.random() * randomizedBgImages.length),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgImageIndex(
        (prevIndex) => (prevIndex + 1) % randomizedBgImages.length,
      );
    }, 6000); // Interval between image changes

    return () => clearInterval(interval);
  }, [randomizedBgImages]);

  return (
    <div className="flex min-h-full flex-1">
      <div className="relative hidden w-0 flex-1 lg:block">
        <div className="absolute z-20">
          <div className="relative p-8">
            <QVirtualLogo />
          </div>
        </div>
        <div className="absolute inset-0 z-10 bg-black/20" />
        <div className="absolute inset-0 overflow-hidden bg-black">
          {randomizedBgImages.map((bgImage, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-all duration-1500 ease-in-out ${currentBgImageIndex === index ? "scale-100 opacity-100" : "scale-105 opacity-0"}`}
              style={{ zIndex: currentBgImageIndex === index ? 1 : 0 }}
            >
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={bgImage}
                alt=""
              />
            </div>
          ))}
        </div>
        {isChristmasPeriod() && (
          <Snowfall
            style={{ zIndex: "10" }}
            // The color of the snowflake, can be any valid CSS color.
            color="#FFF"
            // Applied to the canvas element.
            // Controls the number of snowflakes that are created (defaults to 150).
            snowflakeCount={200}
            speed={[0.5, 1]}
          />
        )}
      </div>

      <div className="absolute inset-0 z-10 flex h-full flex-1 lg:inset-auto lg:right-0">
        <div className="mx-auto w-[500px] overflow-hidden rounded-lg bg-background/20 shadow-none backdrop-blur lg:m-8 lg:shadow-md">
          {randomizedBgImages.map((bgImage, index) => (
            <div
              key={index}
              className={`absolute inset-0 hidden transition-all duration-1500 ease-in-out lg:block ${currentBgImageIndex === index ? "scale-100 opacity-40" : "scale-105 opacity-0"}`}
              style={{ zIndex: currentBgImageIndex === index ? 1 : 0 }}
            >
              <img
                className="absolute inset-0 h-full w-full rotate-180 scale-150 object-cover blur-[60px]"
                src={bgImage}
                alt=""
              />
            </div>
          ))}
          <div className="absolute left-4 top-4 lg:hidden">
            <div className="relative">
              <img
                src={themedLogoImage}
                alt="QVirtual's logo"
                className="h-10"
              />
            </div>
          </div>
          <BorderBeam className="hidden lg:block" />
          <div className="absolute inset-0 z-20">{children}</div>
        </div>
      </div>
    </div>
  );
}
