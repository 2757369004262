import { Link } from "@inertiajs/react";
import * as React from "react";

import { Icon } from "@/components/Icon";
import { SortableItemHandle } from "@/components/sortable";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { TODO } from "@/types";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full flex-1 overflow-auto border-t">
    <div
      ref={ref}
      className={cn("table w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "text-table-header-default-text sticky top-0 z-10 table-header-group rounded-[inherit] bg-muted/60 shadow backdrop-blur-sm",
      className,
    )}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "bg-table-body-default-bg text-table-body-default-text table-row-group",
      className,
    )}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={className} {...props} />
));
TableFooter.displayName = "TableFooter";

type BaseProps = {
  className?: string;
};

type TableRowLinkProps = React.ComponentProps<typeof Link>;
type TableRowDivProps = React.HTMLAttributes<HTMLDivElement>;

// More specific discriminated union type
type Props =
  | (BaseProps &
      ({
        asLink: true;
        ref?: React.ComponentRef<typeof Link>;
      } & TableRowLinkProps))
  | ({
      asLink?: false;
      ref?: React.RefObject<HTMLDivElement>;
    } & TableRowDivProps);

const TableRow = React.forwardRef<HTMLDivElement | typeof Link, Props>(
  ({ className, asLink, ...props }, ref) => {
    const Comp = asLink ? Link : "div";

    return (
      <Comp
        ref={ref as TODO}
        data-link={asLink}
        className={cn(
          "bg-table-body-default-bg border-table-body-default-border table-row border-b",
          "hover:bg-zinc-50/60 dark:hover:bg-zinc-900/60",
          className,
        )}
        {...(props as TODO)}
      />
    );
  },
);

TableRow.displayName = "TableRow";

export const RowDragHandleCell = ({
  disabled = false,
  disabledMessage,
}: {
  disabled?: boolean;
  disabledMessage?: string;
}) => {
  const button = (
    <button className="relative after:absolute after:inset-[-12px] after:content-['']">
      <Icon
        icon={"fa-solid fa-grip-vertical"}
        className="text-muted-foreground opacity-0 transition-opacity duration-75 group-hover/row:opacity-100"
      />
    </button>
  );

  if (!disabled) {
    return <SortableItemHandle asChild>{button}</SortableItemHandle>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className={"cursor-none opacity-50"}>{button}</div>
      </TooltipTrigger>
      <TooltipContent side={"right"}>
        {disabledMessage || "Unable to change order"}
      </TooltipContent>
    </Tooltip>
  );
};

const TableHeadRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("bg-table-body-default-bg table-row", className)}
    {...props}
  />
));
TableHeadRow.displayName = "TableHeadRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "table-cell cursor-pointer text-sm font-normal uppercase text-foreground/80 hover:bg-zinc-200/40 dark:hover:bg-zinc-800/60",
      "h-11 whitespace-nowrap px-2 text-left align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "table-cell p-1 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableHeadRow,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
