import { router } from "@inertiajs/react";
import { useCallback, useEffect } from "react";

import { usePageData } from "@/hooks/usePageData";

export const useQvNavigation = () => {
  const { navigation } = usePageData();

  const refetch = useCallback(() => {
    router.reload({
      only: ["navigation", "tableData"],
    });
  }, []);

  useEffect(() => {
    window.Echo.channel("qvac-navigation").listen(".changed", refetch);
  }, [refetch]);

  return navigation;
};
