import { Link, router } from "@inertiajs/react";
import * as React from "react";

import { Icon } from "@/components/Icon";
import { NavMain } from "@/components/nav-main";
import { NavUser } from "@/components/nav-user";
import { QVirtualLogo } from "@/components/qvirtual-logo";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarRail,
} from "@/components/ui/sidebar";
import { usePageData } from "@/hooks/usePageData";
import { useQvNavigation } from "@/hooks/useQvNavigation";
import { useTheme } from "@/providers/ThemeProvider";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { theme, toggleTheme } = useTheme();
  const navigation = useQvNavigation();
  const { auth } = usePageData();
  const adminSection = window.location.pathname.startsWith("/qvac");
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <Link href={"/"}>
          <QVirtualLogo />
        </Link>
      </SidebarHeader>
      <SidebarContent>
        {navigation &&
          navigation?.map(({ section, items }, idx) => (
            <NavMain header={section} items={items} key={idx} />
          ))}
      </SidebarContent>
      <SidebarFooter>
        <SidebarGroup>
          <SidebarMenu>
            {auth.staff && adminSection && (
              <SidebarMenuButton tooltip={"VA Settings"} asChild>
                <Link href={route("qvac.settings.tab")}>
                  <Icon icon={"fa-cog"} iconStyle="SOLID" />
                  <span>Global settings</span>
                </Link>
              </SidebarMenuButton>
            )}
            {auth.use_react && (
              <SidebarMenuButton
                tooltip={"Switch to classic"}
                onClick={() => {
                  router.post(route("switch-to-classic"));
                }}
              >
                <Icon icon={"fa-sparkles"} iconStyle="SOLID" />
                <span>Switch to classic</span>
              </SidebarMenuButton>
            )}
            {!adminSection && (
              <>
                <SidebarMenuButton
                  tooltip={"Discord"}
                  onClick={() => {
                    const link = "https://discord.qvirtual.com.au";
                    window.open(link, "_blank");
                  }}
                >
                  <Icon icon={"fa-discord fa-brands"} iconStyle="SOLID" />
                  <span>Discord</span>
                </SidebarMenuButton>
              </>
            )}
            <SidebarMenuButton
              tooltip={"Toggle theme"}
              onClick={() => toggleTheme()}
            >
              {theme === "light" ? (
                <Icon icon={"fa-moon"} iconStyle="SOLID" />
              ) : (
                <Icon icon={"fa-sun-bright"} />
              )}
              <span>Toggle theme</span>
            </SidebarMenuButton>
          </SidebarMenu>
        </SidebarGroup>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
