import { Link } from "@inertiajs/react";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import * as React from "react";

import { ButtonProps, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const Pagination = ({ className, ...props }: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);
Pagination.displayName = "Pagination";

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn(
      "inline-flex items-center gap-0 -space-x-px rounded-lg shadow-sm shadow-black/5 rtl:space-x-reverse",
      className,
    )}
    {...props}
  />
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, "size"> &
  Omit<React.ComponentProps<typeof Link>, "size">;

const PaginationLink = ({
  className,
  isActive,
  size = "icon",
  ...props
}: PaginationLinkProps) => (
  <Link
    aria-current={isActive ? "page" : undefined}
    className={cn(
      buttonVariants({
        variant: "outline",
        size,
      }),
      "rounded-none shadow-none aria-disabled:pointer-events-none focus-visible:z-10 [&[aria-disabled]>svg]:opacity-50",
      isActive && "bg-accent",
    )}
    {...props}
  />
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({
  className,
  size = "icon",
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    aria-disabled={props.disabled}
    className={cn(
      buttonVariants({
        variant: "outline",
        size,
      }),
      "rounded-none shadow-none aria-disabled:pointer-events-none focus-visible:z-10 [&[aria-disabled]>svg]:opacity-50",
    )}
    {...props}
  >
    <ChevronLeft size={16} strokeWidth={2} aria-hidden="true" />
  </PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = ({
  className,
  size = "icon",
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    aria-disabled={props.disabled}
    className={cn(
      buttonVariants({
        variant: "outline",
        size,
      }),
      "rounded-none shadow-none aria-disabled:pointer-events-none focus-visible:z-10 [&[aria-disabled]>svg]:opacity-50",
    )}
    {...props}
  >
    <ChevronRight size={16} strokeWidth={2} aria-hidden="true" />
  </PaginationLink>
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn(
      buttonVariants({
        variant: "outline",
        size: "icon",
      }),
      "pointer-events-none rounded-none shadow-none",
    )}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
};
