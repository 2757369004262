import { Link } from "@inertiajs/react";
import { ChevronRight } from "lucide-react";

import { Icon } from "@/components/Icon";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { NavItem, usePageData } from "@/hooks/usePageData";

export function NavMain({
  header,
  items,
}: {
  header?: string;
  items: NavItem[];
}) {
  return (
    <SidebarGroup>
      {header && <SidebarGroupLabel>{header}</SidebarGroupLabel>}
      <SidebarMenu>
        {items.map((item) =>
          item.submenu && item.submenu?.length > 0 ? (
            <Dropdown item={item} key={item.id} />
          ) : (
            <NavLink key={item.id} item={item} />
          ),
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}

const NavLink = ({
  item,
  showIcon = true,
}: {
  item: NavItem;
  showIcon?: boolean;
}) => {
  const Element = item.new_window ? "a" : Link;
  const { parentUrl } = usePageData<{ parentUrl?: string }>();
  const isActive =
    window.location.href.split("?")[0] === item.link || parentUrl === item.link;
  return (
    <SidebarMenuItem key={item.id}>
      <SidebarMenuButton tooltip={item.label} asChild isActive={isActive}>
        <Element
          href={item.link as string}
          target={item.new_window ? "_blank" : ""}
        >
          {item.icon && showIcon && (
            <Icon icon={item.icon} iconStyle={"SOLID"} />
          )}
          <span>{item.label}</span>
        </Element>
      </SidebarMenuButton>
      <SidebarMenuBadge>{item.badge}</SidebarMenuBadge>
    </SidebarMenuItem>
  );
};

const Dropdown = ({ item }: { item: NavItem }) => {
  const { parentUrl } = usePageData<{ parentUrl?: string }>();
  const sideMenuHasActiveItem = item.submenu?.some(
    (subItem) =>
      window.location.href.split("?")[0] === subItem.link ||
      parentUrl === subItem.link,
  );
  return (
    <Collapsible
      key={item.id}
      asChild
      defaultOpen={sideMenuHasActiveItem}
      className="group/collapsible"
    >
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton
            tooltip={item.label}
            isActive={sideMenuHasActiveItem}
          >
            {item.icon && <Icon icon={item.icon} />}
            <span>{item.label}</span>
            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {item.submenu?.map((subItem) => (
              <SidebarMenuSubItem key={subItem.label}>
                <SidebarMenuSubButton asChild>
                  <NavLink key={subItem.id} item={subItem} showIcon={false} />
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};
