import * as React from "react";

import * as KbdUi from "@/components/ui/kbd";

const Kbd = (props: { kbd?: string }) => {
  if (!props.kbd) return null;
  return (
    <div className="pointer-events-none absolute inset-y-0 end-0 flex items-center justify-center pe-2 text-muted-foreground">
      <KbdUi.Root>
        <KbdUi.Key>{props.kbd}</KbdUi.Key>
      </KbdUi.Root>
    </div>
  );
};

export { Kbd };
